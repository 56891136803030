import { useMutation } from '@tanstack/react-query';
import type { FC } from 'react';
import { useForm } from 'react-hook-form';
import { type InferType, object, string } from 'yup';

import { resetPasswordRequest } from '@/services/auth/auth';

import Button from '../../Button';
import TextInputField from '../../Form/Fields/TextInputField';
import type { AuthPagesProps } from '../AuthDialog';
import AuthDialogTitle from '../AuthDialogTitles/AuthDialogTitle';
import { AuthPageEnum } from '../AuthEnum';

const ForgotPassword: FC<AuthPagesProps> = ({ setCurrentAuthPage }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<InferType<typeof schema>>({
    defaultValues: { email: '' },
  });

  const { mutate } = useMutation({
    mutationFn: resetPasswordRequest,
    mutationKey: ['resetPasswordRequest'],
    onSuccess: () => {
      setCurrentAuthPage(AuthPageEnum.CHECK_EMAIL_TO_RESET_PASSWORD);
    },
  });

  return (
    <div className="flex w-full flex-col gap-8">
      <AuthDialogTitle title="Password recovery" />
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit((formData) => mutate(formData.email))}
      >
        <TextInputField
          sizeVariant="xs"
          variant="light"
          label="Email Address"
          containerClassName="!gap-2"
          labelClassName="text-jb-neutral-1000 text-xs"
          {...register('email')}
          error={errors.email?.message}
        />
        <Button
          variant="jbPrimary"
          prefixIcon="material-symbols:mail"
          size="sm"
          className="w-full"
          type="submit"
        >
          Send email
        </Button>
        <Button
          variant="unstyled"
          size="xs"
          prefixIcon="material-symbols:arrow-back"
          className="w-fit text-jb-neutral-700"
          onClick={() => setCurrentAuthPage(AuthPageEnum.LOGIN_EMAIL)}
        >
          Go Back
        </Button>
      </form>
    </div>
  );
};

export default ForgotPassword;

const schema = object({
  email: string().email().required(),
});
