import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import type { FC } from 'react';

import GoogleLogo from '@/assets/flat-color-icons--google.svg';
import { EnumAccountType, getGoogleAuth } from '@/services/auth/auth';

import Button from '../../Button';
import DividerWithText from '../../DividerWithText';
import type { AuthPagesProps } from '../AuthDialog';
import AuthDialogTitleMoveToSignUp from '../AuthDialogTitles/AuthDialogTitleMoveToSignUp';
import { AuthPageEnum } from '../AuthEnum';

const LogInProvidersAuth: FC<AuthPagesProps> = ({ setCurrentAuthPage }) => {
  const { push } = useRouter();
  const { mutate } = useMutation({
    mutationFn: getGoogleAuth,
    mutationKey: ['google-auth'],
    onSuccess: ({ authorizationUrl }) => {
      push(authorizationUrl);
    },
  });
  const iconSize = 20;
  return (
    <div className="flex w-full flex-col gap-8">
      <AuthDialogTitleMoveToSignUp setCurrentAuthPage={setCurrentAuthPage} />
      <div className="flex flex-col gap-4">
        <Button
          variant="candidateSecondary"
          className="w-full text-neutral-1000"
          onClick={() => mutate(EnumAccountType.CANDIDATE)}
        >
          <GoogleLogo
            style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
          />
          Log in with Google
        </Button>
        <DividerWithText variant="primaryDark">or</DividerWithText>
        <Button
          variant="jbPrimary"
          prefixIcon="material-symbols:mail"
          className="w-full"
          onClick={() => setCurrentAuthPage(AuthPageEnum.LOGIN_EMAIL)}
        >
          Log in with email
        </Button>
      </div>
    </div>
  );
};

export default LogInProvidersAuth;
