import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { FC } from 'react';
import { useForm } from 'react-hook-form';
import { boolean, type InferType, object, string } from 'yup';

import Checkbox from '@/components/Form/Fields/Checkbox';
import Text from '@/components/Text';
import { queries } from '@/queries';
import { login } from '@/services/auth/auth';

import Button from '../../Button';
import TextInputField from '../../Form/Fields/TextInputField';
import type { AuthPagesProps } from '../AuthDialog';
import AuthDialogTitleMoveToSignUp from '../AuthDialogTitles/AuthDialogTitleMoveToSignUp';
import { AuthPageEnum } from '../AuthEnum';

const LogIn: FC<AuthPagesProps> = ({ setCurrentAuthPage, closeDialog }) => {
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<InferType<typeof schema>>({
    defaultValues: { email: '', password: '', rememberMe: false },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: login,
    mutationKey: ['login'],
    onSuccess: () => {
      queryClient.invalidateQueries(queries.profile.me.queryKey);
      closeDialog();
    },
  });

  return (
    <div className="flex w-full flex-col gap-8">
      <AuthDialogTitleMoveToSignUp setCurrentAuthPage={setCurrentAuthPage} />
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit((formData) => mutate(formData))}
      >
        <TextInputField
          sizeVariant="xs"
          errorClassName="text-xs font-semibold"
          variant="light"
          label="Email Address"
          containerClassName="!gap-0"
          labelClassName="text-neutral-900 text-xs"
          {...register('email')}
          error={errors.email?.message}
        />
        <TextInputField
          sizeVariant="xs"
          errorClassName="text-xs font-semibold"
          variant="light"
          containerClassName="!gap-0"
          labelClassName="text-neutral-900 text-xs"
          label="Password"
          type="password"
          {...register('password')}
          error={errors.password?.message}
        >
          <Button
            variant="unstyled"
            size="xs"
            onClick={() => setCurrentAuthPage(AuthPageEnum.FORGOT_PASSWORD)}
            className="!font-normal text-secondary-500"
          >
            <Text variant="body-caption">Forgot your password?</Text>
          </Button>
        </TextInputField>
        <Checkbox
          checkboxClassName="peer-checked:!bg-jb-success-400 !border-neutral-600"
          className="flex items-center !p-0"
          {...register('rememberMe')}
          errorClassName="text-xs font-semibold"
          error={errors.rememberMe}
        >
          <Text variant="body-caption" className="text-jb-neutral-800">
            Remember Me
          </Text>
        </Checkbox>
        <Button
          isLoading={isLoading}
          variant="jbPrimary"
          prefixIcon="material-symbols:mail"
          className="w-full"
          type="submit"
        >
          Log In
        </Button>
        <Button
          variant="unstyled"
          size="xs"
          prefixIcon="material-symbols:arrow-back"
          className="w-fit text-neutral-700"
          onClick={() => setCurrentAuthPage(AuthPageEnum.LOGIN_PROVIDERS)}
        >
          Go Back
        </Button>
      </form>
    </div>
  );
};

export default LogIn;

const schema = object({
  email: string().email().required(),
  password: string().required(),
  rememberMe: boolean(),
});
