type Option<V> = {
  id: number;
  value: V;
  minDiversity: number;
  minLength: number;
};

type FirstOption<V> = Option<V> & {
  minDiversity: 0;
  minLength: 0;
};

export type Options<V> = [FirstOption<V>, ...Option<V>[]];

export type DiversityType = 'lowercase' | 'uppercase' | 'symbol' | 'number';

type Strength = {
  contains: DiversityType[];
  length: number;
  id: number;
  value: string;
};

const defaultOptions: Options<string> = [
  {
    id: 0,
    value: 'Too weak',
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: 'Weak',
    minDiversity: 1,
    minLength: 4,
  },
  {
    id: 2,
    value: 'Medium',
    minDiversity: 2,
    minLength: 8,
  },
  {
    id: 3,
    value: 'Strong',
    minDiversity: 4,
    minLength: 12,
  },
];

const getPasswordStrengthBase = (
  password?: string,
  options: Options<string> = defaultOptions,
  allowedSymbols: string = '!"#$%&\'()*+,-./:;<=>?@[\\\\\\]^_`{|}~'
): Strength => {
  const passwordCopy = password ?? '';

  const localOptions = [...options];
  localOptions[0] = {
    ...localOptions[0],
    minDiversity: 0,
    minLength: 0,
  };

  const rules: { regex: string; message: DiversityType }[] = [
    {
      regex: '[a-z]',
      message: 'lowercase',
    },
    {
      regex: '[A-Z]',
      message: 'uppercase',
    },
    {
      regex: '[0-9]',
      message: 'number',
    },
  ];

  if (allowedSymbols) {
    rules.push({
      regex: `[${allowedSymbols}]`,
      message: 'symbol',
    });
  }

  const contains = rules
    .filter((rule) => new RegExp(`${rule.regex}`).test(passwordCopy))
    .map((rule) => rule.message);

  const { length } = passwordCopy;

  const matchedOption = localOptions
    .filter((option) => contains.length >= option.minDiversity)
    .filter((option) => length >= option.minLength)
    .sort((o1, o2) => o2.id - o1.id)[0];

  const strength: Strength = {
    contains,
    length,
    id: matchedOption?.id ?? 0,
    value: matchedOption?.value ?? 'Too weak',
  };

  return strength;
};

export const getPasswordStrength = ({
  password,
}: {
  password: string;
  passwordError?: boolean;
}) => {
  const strongPasswordRequirementsList = [
    'Password must have at least 8 characters',
    'Include at least one number or special character',
  ];

  const { id: passwordStrengthLevel, value: passwordStrengthValue } =
    getPasswordStrengthBase(password, defaultOptions);

  const passwordStrength = {
    isTooWeek: passwordStrengthLevel === 0,
    isWeak: passwordStrengthLevel === 1,
    isMedium: passwordStrengthLevel === 2,
    isStrong: passwordStrengthLevel === 3,
  };

  return {
    passwordStrength,
    passwordStrengthLevel,
    strongPasswordRequirementsList,
    passwordStrengthValue,
  };
};
