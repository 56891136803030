import type { FC } from 'react';

import Rocket from '@/assets/rocket.svg';
import Button from '@/components/Button';
import AuthDialogTitle from '@/components/CandidateAuth/AuthDialogTitles/AuthDialogTitle';
import AuthPageLayout from '@/components/CandidateAuth/AuthPageLayout';

import type { AuthPagesProps } from '../AuthDialog';
import { AuthPageEnum } from '../AuthEnum';

const SuccessResetPassword: FC<AuthPagesProps> = ({ setCurrentAuthPage }) => {
  return (
    <AuthPageLayout className="bg-neutral-100">
      <div className="w-20">
        <Rocket />
      </div>
      <AuthDialogTitle
        title="Success!"
        subtitle="Your password has been reset successfully! Log in to your account."
      />
      <Button
        className="w-full"
        onClick={() => setCurrentAuthPage(AuthPageEnum.LOGIN_EMAIL)}
        variant="jbPrimary"
      >
        Log In
      </Button>
    </AuthPageLayout>
  );
};

export default SuccessResetPassword;
