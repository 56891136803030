import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';

type Props = {
  className?: string;
};
const AuthPageLayout: FC<PropsWithChildren<Props>> = ({
  className,
  children,
}) => {
  return (
    <div
      className={classNames(
        'flex w-full flex-1 flex-col items-center justify-center gap-6 p-8',
        className
      )}
    >
      {children}
    </div>
  );
};
export default AuthPageLayout;
