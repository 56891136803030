export enum AuthPageEnum {
  LOGIN_EMAIL,
  LOGIN_PROVIDERS,
  FORGOT_PASSWORD,
  SIGNUP_EMAIL,
  SIGNUP_PROVIDERS,
  CHECK_EMAIL_TO_RESET_PASSWORD,
  CHECK_EMAIL_TO_ACTIVATE_ACCOUNT,
  SUCCESS_RESET_PASSWORD,
}
