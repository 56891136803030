import type { Dispatch, SetStateAction } from 'react';

import Button from '@/components/Button';
import Text from '@/components/Text';

import { AuthPageEnum } from '../AuthEnum';
import AuthDialogTitle from './AuthDialogTitle';

const AuthDialogTitleMoveToSignUp = ({
  setCurrentAuthPage,
}: {
  setCurrentAuthPage: Dispatch<SetStateAction<AuthPageEnum>>;
}) => {
  return (
    <AuthDialogTitle title="Log In">
      <Text
        variant="body"
        className="flex flex-row text-neutral-1000 opacity-70"
      >
        Don`t have an account?&nbsp;
        <Button
          variant="unstyled"
          size="xs"
          onClick={() => setCurrentAuthPage(AuthPageEnum.SIGNUP_PROVIDERS)}
          className="!font-normal text-secondary-500"
        >
          Sign up
        </Button>
      </Text>
    </AuthDialogTitle>
  );
};

export default AuthDialogTitleMoveToSignUp;
