import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { type FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { InferType } from 'yup';
import { boolean, object, string } from 'yup';

import Button from '@/components/Button';
import Checkbox from '@/components/Form/Fields/Checkbox';
import TextInputField from '@/components/Form/Fields/TextInputField';
import PasswordStrengthScale from '@/components/PasswordStrengthScale';
import Text from '@/components/Text';
import { candidateRegister } from '@/services/auth/auth';
import { getPasswordStrength } from '@/utils/getPasswordStrength';

import type { AuthPagesProps } from '../AuthDialog';
import AuthDialogTitleMoveToLogIn from '../AuthDialogTitles/AuthDialogTitleMoveToLogIn';
import { AuthPageEnum } from '../AuthEnum';

const SignUp: FC<AuthPagesProps> = ({ setCurrentAuthPage }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { mutate } = useMutation({
    mutationFn: candidateRegister,
    mutationKey: ['register'],
    onSuccess: () =>
      setCurrentAuthPage(AuthPageEnum.CHECK_EMAIL_TO_ACTIVATE_ACCOUNT),
  });

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<InferType<typeof schema>>({
    defaultValues: { email: '', password: '', name: '', privacyPolicy: false },
    resolver: yupResolver(schema),
  });

  const password = watch('password');
  const { passwordStrength, strongPasswordRequirementsList } =
    getPasswordStrength({
      password,
    });
  const passwordStrengthClassNames = classNames({
    '!border-error !text-error':
      passwordStrength.isWeak ||
      (passwordStrength.isTooWeek && password.length > 0) ||
      errors.password?.message,
    '!border-warning !text-warning bg-warning/20': passwordStrength.isMedium,
    '!border-successDark  !text-successDark bg-success/20':
      passwordStrength.isStrong,
  });

  const isValidEmail = emailValidationRegex.test(watch('email'));

  const buttonShowPassword = () => {
    return (
      <Button
        variant="unstyled"
        className="z-10"
        size="xs"
        prefixIcon={
          showPassword
            ? 'material-symbols-light:visibility-outline'
            : 'material-symbols-light:visibility-off-outline'
        }
        onClick={() => setShowPassword((prev) => !prev)}
      />
    );
  };

  return (
    <div className="flex w-full flex-col gap-8">
      <AuthDialogTitleMoveToLogIn setCurrentAuthPage={setCurrentAuthPage} />
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit((formData) => mutate(formData))}
      >
        <TextInputField
          sizeVariant="xs"
          variant="light"
          label="Full Name"
          errorClassName="text-xs font-semibold"
          placeholder="Eg. John Doe"
          containerClassName="!gap-0"
          labelClassName="text-neutral-900 text-xs"
          {...register('name')}
          error={errors.name?.message}
        />
        <TextInputField
          sizeVariant="xs"
          variant="light"
          label="Email Address"
          errorClassName="text-xs font-semibold"
          containerClassName="!gap-0"
          labelClassName="text-neutral-900 text-xs"
          {...register('email')}
          error={errors.email?.message}
          className={classNames({
            'border-successDark bg-success/20': isValidEmail,
          })}
        />
        <div>
          <TextInputField
            sizeVariant="xs"
            variant="light"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            suffixElement={buttonShowPassword()}
            containerClassName="!gap-0"
            labelClassName="text-neutral-900 text-xs"
            {...register('password')}
            error={errors.password?.message}
            canHaveErrorMessage={false}
            className={passwordStrengthClassNames}
          />
          {watch('password').length > 0 && (
            <PasswordStrengthScale
              className="pt-2"
              password={watch('password')}
              setScaleClassName={(isActive) =>
                classNames({
                  'bg-neutral-300': !isActive,
                  'bg-jb-success-400': isActive && passwordStrength.isStrong,
                  'bg-warning': isActive && passwordStrength.isMedium,
                  'bg-jb-alert-400':
                    isActive &&
                    (passwordStrength.isWeak || passwordStrength.isTooWeek),
                })
              }
            />
          )}
          <ul
            className={classNames(
              'ml-4 list-disc text-neutral-600 !bg-transparent',
              passwordStrengthClassNames
            )}
          >
            {strongPasswordRequirementsList.map((requirement) => (
              <li key={requirement}>
                <Text variant="body-caption">{requirement}</Text>
              </li>
            ))}
          </ul>
          <Checkbox
            checkboxClassName="peer-checked:!bg-neutral-900 !border-neutral-600"
            {...register('privacyPolicy')}
            errorClassName="text-xs font-semibold"
            error={errors.privacyPolicy}
          >
            <Text
              className="text-neutral-600 [&>a]:text-secondary-500 [&>a]:underline"
              variant="body-caption"
            >
              I agree with the{' '}
              <a href="https://mindpal.co/terms-of-service/" target="_blank">
                Terms
              </a>{' '}
              and{' '}
              <a href="https://mindpal.co/privacy-policy/" target="_blank">
                Privacy Policy
              </a>
            </Text>
          </Checkbox>
        </div>
        <Button type="submit" variant="jbPrimary" className="w-full">
          Sign Up with email
        </Button>
        <Button
          variant="unstyled"
          size="xs"
          prefixIcon="material-symbols:arrow-back"
          className="w-fit text-neutral-700"
          onClick={() => setCurrentAuthPage(AuthPageEnum.SIGNUP_PROVIDERS)}
        >
          Go Back
        </Button>
      </form>
    </div>
  );
};

export default SignUp;

const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const schema = object({
  name: string().required('Name field is required'),
  email: string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  password: string()
    .min(8, 'Password must be at least 8 characters long')
    .required('Password is required'),
  privacyPolicy: boolean()
    .oneOf([true], 'You must agree to the terms and conditions')
    .required('You must agree to the terms and conditions'),
});
