import type { FC } from 'react';

import PaperAirplane from '@/assets/PaperAirplane.svg';
import Button from '@/components/Button';
import AuthDialogTitle from '@/components/CandidateAuth/AuthDialogTitles/AuthDialogTitle';
import AuthPageLayout from '@/components/CandidateAuth/AuthPageLayout';
import Text from '@/components/Text';

import type { AuthPagesProps } from '../AuthDialog';

const CheckEmailToResetPassword: FC<AuthPagesProps> = ({ closeDialog }) => {
  return (
    <AuthPageLayout className="bg-neutral-100">
      <div className="w-20">
        <PaperAirplane />
      </div>
      <AuthDialogTitle
        title="Check your email"
        subtitle="We have send you an email with link to reset your password. Check your inbox."
      >
        <Text className="pt-2 text-neutral-900" variant="body-caption">
          If you don`t receive the email, contact us at{' '}
          <a
            href="mailto:developer@mindpal.co"
            target="_blank"
            className="text-secondary-500"
          >
            developer@mindpal.co
          </a>
        </Text>
      </AuthDialogTitle>
      <Button variant="jbPrimary" className="w-full" onClick={closeDialog}>
        Continue
      </Button>
    </AuthPageLayout>
  );
};

export default CheckEmailToResetPassword;
