import type {
  DialogContentProps,
  DialogProps,
  DialogTriggerProps,
} from '@radix-ui/react-dialog';
import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import { useState } from 'react';

import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog';
import { AuthPageEnum } from './AuthEnum';
import AuthPageLayout from './AuthPageLayout';
import CheckEmailToActivate from './CheckEmail/CheckEmailToActivate';
import CheckEmailToResetPassword from './CheckEmail/CheckEmailToResetPassword';
import ForgotPassword from './LogIn/ForgotPassword';
import LogIn from './LogIn/LogIn';
import LogInProvidersAuth from './LogIn/LogInProvidersAuth';
import SuccessResetPassword from './LogIn/SuccessResetPassword';
import SignUp from './SignUp/SignUp';
import SignUpProvidersAuth from './SignUp/SignUpProvidersAuth';

type Props = {
  defaultPage: AuthPageEnum;
  dialogTriggerProps?: DialogTriggerProps;
  dialogContentProps?: DialogContentProps;
  dialogProps?: DialogProps;
};
export type AuthPagesProps = {
  setCurrentAuthPage: Dispatch<SetStateAction<AuthPageEnum>>;
  closeDialog: () => void;
};
const AuthDialog: FC<PropsWithChildren<Props>> = ({
  defaultPage,
  children,
  dialogContentProps,
  dialogTriggerProps,
  dialogProps,
}) => {
  const [currentAuthPage, setCurrentAuthPage] = useState<AuthPageEnum>(
    defaultPage ?? AuthPageEnum.LOGIN_EMAIL
  );
  const [isOpen, setIsOpen] = useState(false);
  const closeDialog = () => setIsOpen(false);
  const page = {
    [AuthPageEnum.LOGIN_EMAIL]: (
      <LogIn
        setCurrentAuthPage={setCurrentAuthPage}
        closeDialog={closeDialog}
      />
    ),
    [AuthPageEnum.LOGIN_PROVIDERS]: (
      <LogInProvidersAuth
        setCurrentAuthPage={setCurrentAuthPage}
        closeDialog={closeDialog}
      />
    ),
    [AuthPageEnum.FORGOT_PASSWORD]: (
      <ForgotPassword
        setCurrentAuthPage={setCurrentAuthPage}
        closeDialog={closeDialog}
      />
    ),
    [AuthPageEnum.SIGNUP_EMAIL]: (
      <SignUp
        setCurrentAuthPage={setCurrentAuthPage}
        closeDialog={closeDialog}
      />
    ),
    [AuthPageEnum.SIGNUP_PROVIDERS]: (
      <SignUpProvidersAuth
        setCurrentAuthPage={setCurrentAuthPage}
        closeDialog={closeDialog}
      />
    ),
    [AuthPageEnum.CHECK_EMAIL_TO_RESET_PASSWORD]: (
      <CheckEmailToResetPassword
        closeDialog={closeDialog}
        setCurrentAuthPage={setCurrentAuthPage}
      />
    ),
    [AuthPageEnum.CHECK_EMAIL_TO_ACTIVATE_ACCOUNT]: (
      <CheckEmailToActivate
        closeDialog={closeDialog}
        setCurrentAuthPage={setCurrentAuthPage}
      />
    ),
    [AuthPageEnum.SUCCESS_RESET_PASSWORD]: (
      <SuccessResetPassword
        setCurrentAuthPage={setCurrentAuthPage}
        closeDialog={closeDialog}
      />
    ),
  };

  const handleOnOpenChange = () => {
    setIsOpen((prev) => {
      if (prev) setCurrentAuthPage(defaultPage);
      return !prev;
    });
  };
  return (
    <Dialog open={isOpen} onOpenChange={handleOnOpenChange} {...dialogProps}>
      <DialogTrigger {...dialogTriggerProps}>{children}</DialogTrigger>
      <DialogContent {...dialogContentProps} className="max-w-xl !p-6">
        <AuthPageLayout>{page[currentAuthPage!]}</AuthPageLayout>
      </DialogContent>
    </Dialog>
  );
};

export default AuthDialog;
