import Image from 'next/image';
import type { FC, PropsWithChildren } from 'react';

import DrawnUnderline from '@/assets/DrawnUnderline.webp';

import Text from '../../Text';

type Props = {
  title: string;
  subtitle?: string;
};
const AuthDialogTitle: FC<PropsWithChildren<Props>> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <div className="flex w-full max-w-xl flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-1">
        <Text variant="h3" className="font-semibold text-neutral-900">
          {title}
        </Text>
        <Image src={DrawnUnderline} alt="" />
      </div>
      <Text
        variant="body"
        className="w-full max-w-xs text-center text-neutral-1000 opacity-70"
      >
        {subtitle}
      </Text>
      {children}
    </div>
  );
};

export default AuthDialogTitle;
