import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import type { FC } from 'react';

import { getPasswordStrength } from '@/utils/getPasswordStrength';

import Text from './Text';

type PasswordStrengthScaleProps = {
  password: string;
  setScaleClassName?: (isActive: boolean) => string;
  className?: string;
};

const PasswordStrengthScale: FC<PasswordStrengthScaleProps> = ({
  password,
  className,
  setScaleClassName,
}) => {
  const { passwordStrengthLevel, passwordStrengthValue } = getPasswordStrength({
    password,
  });

  return (
    <AnimatePresence>
      <motion.div
        layout
        className={classNames('flex flex-row items-center gap-1', className)}
      >
        {Array.from({ length: 3 }, (_, i) => (
          <div
            key={i}
            className={classNames(
              'rounded-full h-1 flex-1',
              setScaleClassName?.(
                i === 0 && password.length > 0
                  ? true
                  : passwordStrengthLevel > i
              )
            )}
          />
        ))}
        <Text variant="body-small" className="leading-[100%] text-neutral-1000">
          {passwordStrengthValue}
        </Text>
      </motion.div>
    </AnimatePresence>
  );
};

export default PasswordStrengthScale;
